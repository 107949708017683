import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ef9bf00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-wrapper" }
const _hoisted_4 = ["href", "textContent"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!
  const _component_Flex = _resolveComponent("Flex")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, { size: "large" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.block?.Bg && 'background')
      }, [
        _createVNode(_component_Container, { size: "medium" }, {
          default: _withCtx(() => [
            _createVNode(_component_Flex, {
              direction: `${_ctx.block?.Reverse ? 'row-reverse' : 'row'}`,
              justifyContent: "space-between",
              alignItems: "center",
              wrap: "wrap"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("img", {
                    src: _ctx.block ? _ctx.backendUrl + _ctx.block?.Img.data.attributes.url : '',
                    alt: ""
                  }, null, 8, _hoisted_2)
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_Text, null, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: _ctx.block?.SpecialTextHref ?? '#test',
                        target: "_blank",
                        textContent: _toDisplayString(_ctx.block?.SpecialText + ' '),
                        class: _normalizeClass(`${_ctx.block?.SpecialTextColor}`)
                      }, null, 10, _hoisted_4),
                      _createElementVNode("span", {
                        innerHTML: _ctx.block?.Text
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["direction"])
          ]),
          _: 1
        })
      ], 2)
    ]),
    _: 1
  }))
}