import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83abfda0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, { size: "medium" }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: _ctx.block ? _ctx.backendUrl + _ctx.block?.Img.data.attributes.url : '',
        alt: ""
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}