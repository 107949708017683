
//Atoms
import Text from "@/components/atoms/Text.vue";

export default {
  components: {
    Text,
  },
  props: {
    img: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  // eslint-disable-next-line
  setup() {
    return {};
  },
};
