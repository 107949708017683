
import { defineComponent } from "vue";

//Layouts
import Container from "../../layouts/Container.vue";
import Text from "@/components/atoms/Text.vue";
import Flex from "@/components/layouts/Flex.vue";

export default defineComponent({
  components: {
    Container,
    Text,
    Flex,
  },
  props: {
    block: {
      type: Object,
    },
  },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    return { backendUrl };
  },
});
