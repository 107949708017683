import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a2e09e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-title-text" }
const _hoisted_2 = { class: "icon-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $props.img,
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Text, {
        class: "title",
        value: $props.title,
        size: "large",
        weight: "bold"
      }, null, 8, ["value"]),
      _createVNode(_component_Text, {
        value: $props.text,
        size: "medium"
      }, null, 8, ["value"])
    ])
  ]))
}