
import { defineComponent } from "vue";

//Layouts
import Container from "@/components/layouts/Container.vue";

//Molecules
import Flex from "@/components/layouts/Flex.vue";
import Title from "@/components/atoms/Title.vue";
import IconTitleText from "@/components/molecules/IconTitleText.vue";

export default defineComponent({
  components: { Container, Flex, Title, IconTitleText },
  props: {
    block: {
      type: Object,
    },
  },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    return { backendUrl };
  },
});
