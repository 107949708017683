
import { defineComponent } from "vue";

//Atoms
import Container from "../../layouts/Container.vue";

export default defineComponent({
  components: { Container },
  props: {
    block: {
      type: Object,
    },
  },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    return { backendUrl };
  },
});
