import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac9232e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_IconTitleText = _resolveComponent("IconTitleText")!
  const _component_Flex = _resolveComponent("Flex")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.block)
      ? (_openBlock(), _createBlock(_component_Container, {
          key: 0,
          size: "large"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Title, {
              value: _ctx.block?.Title
            }, null, 8, ["value"]),
            _createVNode(_component_Flex, { wrap: "wrap" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.block.ImgTitleText, (item, index) => {
                  return (_openBlock(), _createBlock(_component_IconTitleText, {
                    key: index,
                    img: item ? _ctx.backendUrl + item.Img.data.attributes.url : '',
                    title: item.Title,
                    text: item.Text
                  }, null, 8, ["img", "title", "text"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}