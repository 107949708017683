
import { defineComponent } from "vue";

//Layouts
import Container from "../../layouts/Container.vue";

//Atoms
import Title from "../../atoms/Title.vue";

export default defineComponent({
  components: { Container, Title },
  props: {
    block: {
      type: Object,
    },
  },
  setup() {
    return {};
  },
});
